import './App.css';
import CatalogGrid from "./CatalogGrid";
import Screen from "./Screen";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Blank from "./Blank";
import GcmrScreen from "./GcmrScreen";
import OODBScreen from "./OODBScreen";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path={'/map-sessions/v1'} element={<Blank/>}/>
                <Route exact path={'/oodb'} element={<OODBScreen/>}/>
                <Route exact path={'/gcmr'} element={<GcmrScreen/>}/>
                <Route exact path={'/'} element={<Screen/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
