import React from 'react';
import {
    Grid,
    AppBar,
    Toolbar,
    Typography,
    Box,
    Card,
    ToggleButton,
    Autocomplete,
    TextField,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Checkbox,
    ListItemIcon,
    Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, Stack
} from "@mui/material";
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import NorthWestIcon from '@mui/icons-material/NorthWest';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ClearIcon from '@mui/icons-material/Clear';

import {TreeItem} from "@mui/lab";
import GcmrMap from "./GcmrMap";
import {DataGrid} from "@mui/x-data-grid";
import axios from 'axios';

class GridFooter extends React.Component {
    render() {
        return (
            <Stack direction={"column"} spacing={1} sx={{padding:'5px'}}>
                <Button variant="contained" onClick={this.props.onClick} size={'small'} sx={{width:'90px'}}>Заказать</Button>
                <Button variant="contained" onClick={this.props.clearCart} size={'small'} sx={{width:'90px'}}>Очистить</Button>
            </Stack>
        );
    }
}

export default class GcmrScreen extends React.Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();

        this.girRef = React.createRef();
        this.listRef = React.createRef();

        this.state = {
            feature: null,
            showPreview: false,
            cart: [],
            options: [],
            data: [
                {label: 'Генеральные 1:5000000 - 1:1000000', checked: true, id: 0},
                {label: 'Путевые 1 1:750000 - 1:500000', checked: true, id: 1},
                {label: 'Путевые 2 1:500000 - 1:100000', checked: true, id: 2},
                {label: 'Частные 1:100000 - 1:25000', checked: true, id: 3},
                {label: 'Морские планы 1:25000 - 1:500', checked: true, id: 4},
            ],
            regions: false,
            girItems: [],
            girValue: null,
            listValue: null,
            listItems: [
                {
                    label: 'Отмытый рельеф', checked: true, id: 0, layers: [
                        'gcmrhillshade', 'gcmrhillshade9'
                    ]
                },
                {
                    label: 'Метаданные ГЦМР', checked: true, id: 1, layers: [
                        'gcmr_mdETKOV_122111', 'gcmr_mdENMK_717000-selected', 'gcmr_mdENMK_717000-filter'
                    ]
                },
            ],
            map: null,
            select: 'point',
            tableSelected: [],
            open: false,
            filterOptions: [],
            filterValue: null
        }

        this.selectFeature = (feature) => {
            const state = this.state;
            state.feature = feature;
            this.setState(state);
        };

        this.showPreview = () => {
            const state = this.state;
            state.selected = !state.selected;
            if (!state.selected)
                this.mapRef.current.hidePreview();
            this.setState(state);
            this.mapRef.current.clearFeatures();
        };

        this.onSelected = (feature) => {
            const state = this.state;
            if (!state.selected)
                return;
            this.mapRef.current.showPreview(feature.id);
        };

        this.idCounter = 1000000;

        this.addToCart = () => {
            const map = this.mapRef.current;
            let fc = map.draw.getAll();
            if (!(fc.features.length + map.features.length))
                return;
            fc.features.forEach((x) => {
                this.fillAttributes(x);
                x.id = this.idCounter++;
            });
            const fids = map.features.map((x)=> x.id);
            if (fids.length) {
                axios
                    .post('https://es.ivazh.ru/gcmr/_search', {
                        query: {
                            terms: {
                                '_id': fids
                            }
                        }
                    })
                    .then(res => {
                        const fss = res.data.hits.hits.map(h => h._source.feature);
                        fc.features = fc.features.concat(fss);
                        const ids = new Set(this.state.cart.map((i) => i.id));
                        let fs = fc.features.filter((i) => !ids.has(i.id));

                        const features = this.state.cart.concat(fs);
                        this.setState({cart: features});
                        this.mapRef.current.cartFeatures(features);
                        map.clearFeatures();
                    })
            } else {
                const ids = new Set(this.state.cart.map((i) => i.id));
                let fs = fc.features.filter((i) => !ids.has(i.id));

                const features = this.state.cart.concat(fs);
                this.setState({cart: features});
                this.mapRef.current.cartFeatures(features);
                map.clearFeatures();
            }
        }

        this.clearCart = () => {
            const state = this.state;
            state.cart = [];
            this.setState(state);
            this.mapRef.current.cartFeatures(state.cart);
        }

        this.clearSelection = () => {
            const map = this.mapRef.current;
            map.clearFeatures();
        }

        this.onClick = () => {
            console.log('click');
        }

        this.onInputChange = (event, newValue) => {
            axios
                .post('https://es.ivazh.ru/regions/_search', {
                    query: {
                        match_phrase_prefix: {
                            name: newValue
                        }
                    }
                })
                .then(res => {
                    const results = res.data.hits.hits.map(h => h._source);
                    this.setState({options: results});
                })
        }

        this.onSearchChange = (event, newValue) => {
            const map = this.mapRef.current;
            if (!newValue)
                return;
            console.log(newValue);
            map.zoomTo(newValue.lat, newValue.lon, newValue.level);
        }

        this.onFilterInputChange = (event, newValue) => {
            axios
                .post('https://es.ivazh.ru/filters/_search', {
                    query: {
                        match_phrase_prefix: {
                            name: newValue
                        }
                    }
                })
                .then(res => {
                    const results = res.data.hits.hits.map(h => h._source);
                    this.setState({filterOptions: results});
                })
        }

        this.onFilterSearchChange = (event, newValue) => {
            this.setState({filterValue: newValue});
        }

        this.onRegionStateChange = () => {
            const status = !this.state.regions;
            this.setState({regions: status});
            const map = this.mapRef.current;
            if (status) {
                map.showMap('rg', 'https://osm.ivazh.ru/styles/regions/style.json');
            } else {
                map.hideMap('rg', 'https://osm.ivazh.ru/styles/regions/style.json');
            }
        }

        this.handleGirChange = (event, value) => {
            if (!value)
                return;
            if (value.label === 'НПД')
                window.location = '/';
            if (value.label === 'ГЦМР')
                window.location = '/gcmr';
            if (value.label === 'ООБД')
                window.location = '/oodb';
        }

        this.customList = (items) => {
            return (
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {
                        items.map((value) => {
                            return (
                                <ListItem key={value.id} disablePadding>
                                    <ListItemButton role={undefined} dense onClick={this.handleToggle(value.id, value.layers)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={this.state.data[value.id].checked}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': `checkbox-list-label-${value.id}` }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={`checkbox-list-label-${value.id}`} primary={value.label} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })
                    }
                </List>
            );
        }

        this.handleListChange = (event, value) => {
            const map = this.mapRef.current;
            if (this.state.map)
                map.hideMap(this.state.map.id, this.state.map.url);
            if (value.items) {
                map.showMap('', value.url);
                this.setState({listValue: value, listItems: value.items, map: {id: '', url: value.url},
                    data:[
                        {label: 'Генеральные 1:5000000 - 1:1000000', checked: true, id: 0},
                        {label: 'Путевые 1 1:750000 - 1:500000', checked: true, id: 1},
                        {label: 'Путевые 2 1:500000 - 1:100000', checked: true, id: 2},
                        {label: 'Частные 1:100000 - 1:25000', checked: true, id: 3},
                        {label: 'Морские планы 1:25000 - 1:500', checked: true, id: 4}
                    ]});
            } else {
                this.setState({listValue: value, listItems: [], map: null});
            }
        }

        this.drawLine = () => {
            this.setState({select: 'line'});
            this.mapRef.current.drawLine();
        }

        this.drawPolygon = () => {
            this.setState({select: 'polygon'});
            this.mapRef.current.drawPolygon();
        }

        this.drawFinished = () => {
            this.setState({select: 'point'});
        }

        this.highlightFeature = (items) => {
            this.setState({tableSelected: items});
            const sitems = new Set(items);
            let features = this.state.cart.filter((x) => sitems.has(x.id));
            if (features.length) {
                this.mapRef.current.highlightFeatures(features);
            } else {
                this.mapRef.current.highlightFeatures();
            }
        }

        this.clearHighlight = () => {
            this.setState({tableSelected: []});
        }

        this.handleClose = () => {
            this.setState({open: false});
            this.mapRef.current.filterRegion(this.state.filterValue.feature);
        }

        this.handleClear = () => {
            this.setState({open: false});
        }

        this.handleDialog = () => {
            this.setState({open: true});
        }
    }

    girTypes = [
        { label: 'НПД'  },
        { label: 'ЕЦПЗ' },
        { label: 'ГЦМР' },
        { label: 'ООБД' }
    ];

    renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => this.renderTree(node))
                : null}
        </TreeItem>
    );

    checked = [];

    handleToggle = (value, layers) => () => {
        const data = this.state.data;
        data[value].checked = !data[value].checked;
        this.setState({data: data});
        const map = this.mapRef.current.mapRef.current;
        layers.forEach((item) => {
            map.setLayoutProperty(item, 'visibility', data[value].checked ? 'visible': 'none');
        });
    };

    columns = [
        {field: 'id', headerName: 'ID', sortable: true},
        {field: 'c122', headerName: 'Наименование', flex: 1, sortable: true, valueGetter: (params) => `${params.row.properties.c122 || ''}`},
        {field: 'c202', headerName: 'Номенклатура', flex: 1, sortable: true, valueGetter: (params) => `${params.row.properties.c202 || ''}`},
        {field: 'c103', headerName: 'Секретность', flex: 1, sortable: true, valueGetter: (params) => `${params.row.properties.c103 || ''}`},
        {field: 'c201', headerName: 'Масштаб', sortable: true, valueGetter: (params) => `1 : ${params.row.properties.c201}`},
        {field: 'c236', headerName: 'Тип карты', width: 150, sortable: true, valueGetter: (params) => `${params.row.properties.c236}`},
    ];

    rows = [];

    render() {
        return (
            <Grid container>
                <Grid container xs sx={{ height: '100vh' }} direction={"column"}>
                    <Grid item xs sx={{ height: 'calc(100vh - 64px)' }}>
                        <AppBar position={"static"} color={'default'}>
                            <Toolbar variant={"regular"}>
                                <Autocomplete
                                    disablePortal
                                    size={"small"}
                                    id="combo-box-demo"
                                    options={this.girTypes}
                                    value={this.girTypes[2]}
                                    onChange={this.handleGirChange}
                                    sx={{ flexGrow: 1, flexDirection: 'row' }}
                                    renderInput={(params) => <TextField {...params} label="Вид ГИР" />}
                                />
                            </Toolbar>
                        </AppBar>
                        <Box sx={{padding:2}} >
                            {this.customList(this.state.listItems)}
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Card sx={{ height:'calc(100% - 32px)', padding:2 }}>
                            <Typography align={"center"}>Информация</Typography>
                            <Typography>
                                Источник: {this.state.feature ? this.state.feature.properties.source_kind : ''}
                            </Typography>
                            <Typography>
                                Разрешение: {this.state.feature ? this.state.feature.properties.source_res + 'м' : ''}
                            </Typography>
                            <Typography>
                                Год состояния местности: {this.state.feature ? this.state.feature.properties.year_of_state : ''}
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container xs={10} sx={{ height: '100vh' }} direction={"column"}>
                    <Grid item xs sx={{height: 'calc(100vh - 64px)'}}>
                        <AppBar position={'sticky'} color={'default'}>
                            <Toolbar variant={"regular"}>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    selected={this.state.regions}
                                    onChange={this.onRegionStateChange}
                                    sx={{ mr: 1 }}
                                >
                                    <SouthAmericaIcon />
                                </ToggleButton>
                                <Autocomplete
                                    autoComplete
                                    disablePortal
                                    size={"small"}
                                    options={this.state.options}
                                    getOptionLabel={(option) =>
                                        typeof option === 'string' ? option : option.full_name
                                    }
                                    sx={{ width: 350, mr: 1 }}
                                    filterOptions={(x) => x}
                                    onChange={this.onSearchChange}
                                    onInputChange={this.onInputChange}
                                    renderInput={(params) => <TextField {...params} label="Адресный поиск" />}
                                />
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 4 }}
                                    onClick={this.handleDialog}
                                >
                                    <FilterAltIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    selected={this.state.select === 'point'}
                                    title={'Выбрать'}
                                >
                                    <NorthWestIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    selected={this.state.select === 'polygon'}
                                    title={'Нанести полигон'}
                                    onChange={this.drawPolygon}
                                >
                                    <CropSquareIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    onChange={this.addToCart}
                                    title={'Добавить в корзину'}
                                >
                                    <AddShoppingCartIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    onChange={this.clearSelection}
                                    title={'Очистить'}
                                >
                                    <ClearIcon />
                                </ToggleButton>
                            </Toolbar>
                        </AppBar>
                        <Box sx={{height:'calc(100% - 64px)'}}>
                            <GcmrMap clearHighlight={this.clearHighlight}
                                     selectFeature={this.selectFeature} drawFinished={this.drawFinished}
                                     selected={this.onSelected} cart={this.state.cart}
                                     ref={this.mapRef}
                            />
                            <Dialog open={this.state.open} onClose={this.handleClose} >
                                <DialogTitle>Фильтр по регионам</DialogTitle>
                                <DialogContent sx={{height:'500px', width: '500px'}}>
                                    <DialogContentText>
                                        Выберите регион с помощью поля ввода
                                    </DialogContentText>
                                    <Autocomplete
                                        autoComplete
                                        disablePortal
                                        size={"small"}
                                        options={this.state.filterOptions}
                                        getOptionLabel={(option) =>
                                            typeof option === 'string' ? option : option.full_name
                                        }
                                        sx={{ mr: 1, mt: 1 }}
                                        filterOptions={(x) => x}
                                        onChange={this.onFilterSearchChange}
                                        onInputChange={this.onFilterInputChange}
                                        renderInput={(params) => <TextField {...params} label="Фильтр" />}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose}>Выбрать</Button>
                                    <Button onClick={this.handleClear}>Отмена</Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container direction={"row"} sx={{height:'100%'}}>
                            <Grid item xs>
                                <DataGrid
                                    columns={this.columns} rows={this.state.cart}
                                    density={'compact'}
                                    editMode={"row"}
                                    onSelectionModelChange={this.highlightFeature}
                                    checkboxSelection={true}
                                    selectionModel={this.state.tableSelected}
                                    pageSize={100}
                                />
                            </Grid>
                            <Grid item xs={'10px'}>
                                <GridFooter onClick={this.onClick} clearCart={this.clearCart}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
