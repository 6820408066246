import React from 'react';
import mapboxgl from 'mapbox-gl';
import {Box} from "@mui/material";
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxDraw from "@mapbox/mapbox-gl-draw";


mapboxgl.config.API_URL = 'https://react.ivazh.ru'
mapboxgl.accessToken = 'pk';

export default class GcmrMap extends React.Component {
    constructor(props) {
        super(props);
        this.mapContainerRef = React.createRef();
        this.mapRef = React.createRef();
        this.state = {
            lat: 0,
            lng: 0,
            zoom: 0
        }
        this.data = {
            'type': 'FeatureCollection',
            'features': []
        }

        this.clickEnabled = true;
        this.feature = [];
        this.features = [];

        this.getStyle = async (url) => {
            const query = await fetch(url, {method: 'GET'});
            return await query.json();
        }

        this.showMap = async (id, url) => {
            const data = await this.getStyle(url);
            const map = this.mapRef.current;
            Object.entries(data.sources).forEach(([key, value]) => {
                map.addSource(`${id}${key}`, value);
            });
            data.layers.forEach((layer) => {
                layer.source = `${id}${layer.source}`;
                layer.id = `${id}${layer.id}`;
                map.addLayer(layer);
            });
        }

        this.hideMap = async (id, url) => {
            const data = await this.getStyle(url);
            const map = this.mapRef.current;
            data.layers.forEach((layer) => {
                map.removeLayer(`${id}${layer.id}`);
            });
            Object.entries(data.sources).forEach(([key, _]) => {
                if (map.getSource(`${id}${key}`)) {
                    map.removeSource(`${id}${key}`);
                }
            });
        }

        this.drawPolygon = () => {
            if (!this.draw)
                return;
            this.draw.deleteAll();
            this.draw.changeMode('draw_polygon');
            this.clickEnabled = false;
        }

        this.fillAttributes = (f) => {
            f.properties.c122 = f.id;
            f.properties.c202 = '-';
            f.properties.c103 = 'Не секретно';
            f.properties.c201 = 100000;
            f.properties.c236 = 'Выгрузка ГЦМР';
        }

        this.onDrawFinished = (_) => {
            this.props.drawFinished();
            setTimeout(() => {this.clickEnabled = true;}, 500);
        }

        this.clearFeatures = () => {
            if (!this.draw)
                return;
            this.draw.deleteAll();
            this.features = [];
            this.mapRef.current.setFilter('gcmr_mdENMK_717000-selected', ['any',  ['==', ['id'], '']]);
        }

        this.cartFeatures = (features) => {
            const map = this.mapRef.current;
            this.data.features = features;
            map.getSource('cart').setData(this.data);
        }

        this.highlightFeatures = (f) => {
            const map = this.mapRef.current;
            if (this.feature) {
                this.feature.forEach((x)=> {
                    if (x.layer) {
                        map.setFeatureState({
                            source: 'gcmr_mdpdim',
                            sourceLayer: x.layer['source-layer'],
                            id: x.id
                        }, {
                            highlight: false
                        });
                    } else {
                        map.setFeatureState({
                            source:  'cart',
                            id: x.id
                        }, {
                            highlight: false
                        });
                    }
                });
            }

            this.feature = f;
            if (this.feature) {
                this.feature.forEach((x)=>{
                    if (x.layer) {
                        map.setFeatureState({
                            source: 'gcmr_mdpdim',
                            sourceLayer: x.layer['source-layer'],
                            id: x.id
                        }, {
                            highlight: true
                        });
                    } else {
                        map.setFeatureState({
                            source: 'cart',
                            id: x.id
                        }, {
                            highlight: true
                        });
                    }
                });
            }
        }

        this.zoomTo = (lon, lat, zoom) => {
            this.mapRef.current.flyTo({center: [lat, lon], zoom: zoom});
        }

        this.filterRegion = (f) => {
            this.draw.add(f);
        }

        this.selectFeature = (features) => {
            let feature = null;
            if (features.length > 0) {
                feature = features[0]
                features.forEach(function (f) {
                    if (parseInt(f.properties.c201) < parseInt(feature.properties.c201)) {
                        feature = f
                    }
                });
            }
            return feature;
        }
    }

    componentDidMount() {
        const { lng, lat, zoom } = this.state;
        const map = new mapboxgl.Map({
            container: this.mapContainerRef.current,
            style: 'https://osm.ivazh.ru/styles/basic/style.json',
            center: [lng, lat],
            zoom: zoom,
            doubleClickZoom: false
        });
        this.mapRef.current = map;

        map.addControl(new mapboxgl.NavigationControl(), 'top-right');
        map.addControl(new mapboxgl.ScaleControl(), 'bottom-right');
        map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
        }));

        this.draw = new MapboxDraw({
            controls: {polygon: true},
            displayControlsDefault: false,
            styles: [
                // ACTIVE (being drawn)
                // line stroke
                {
                    "id": "gl-draw-line",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#D20C0C",
                        "line-dasharray": [0.2, 2],
                        "line-width": 2
                    }
                },
                // polygon fill
                {
                    "id": "gl-draw-polygon-fill",
                    "type": "fill",
                    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                    "paint": {
                        "fill-color": "#D20C0C",
                        "fill-outline-color": "#D20C0C",
                        "fill-opacity": 0.1
                    }
                },
                // polygon mid points
                {
                    'id': 'gl-draw-polygon-midpoint',
                    'type': 'circle',
                    'filter': ['all',
                        ['==', '$type', 'Point'],
                        ['==', 'meta', 'midpoint']],
                    'paint': {
                        'circle-radius': 3,
                        'circle-color': '#fbb03b'
                    }
                },
                // polygon outline stroke
                // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
                {
                    "id": "gl-draw-polygon-stroke-active",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#D20C0C",
                        "line-dasharray": [0.2, 2],
                        "line-width": 2
                    }
                },
                // vertex point halos
                {
                    "id": "gl-draw-polygon-and-line-vertex-halo-active",
                    "type": "circle",
                    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                    "paint": {
                        "circle-radius": 5,
                        "circle-color": "#FFF"
                    }
                },
                // vertex points
                {
                    "id": "gl-draw-polygon-and-line-vertex-active",
                    "type": "circle",
                    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                    "paint": {
                        "circle-radius": 3,
                        "circle-color": "#D20C0C",
                    }
                },

                // INACTIVE (static, already drawn)
                // line stroke
                {
                    "id": "gl-draw-line-static",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#000",
                        "line-width": 3
                    }
                },
                // polygon fill
                {
                    "id": "gl-draw-polygon-fill-static",
                    "type": "fill",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                    "paint": {
                        "fill-color": "#000",
                        "fill-outline-color": "#000",
                        "fill-opacity": 0.1
                    }
                },
                // polygon outline
                {
                    "id": "gl-draw-polygon-stroke-static",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#000",
                        "line-width": 3
                    }
                }
                ]
        });


        map.on('load', async () => {
            map.addControl(this.draw);

            await this.showMap('gcmr', 'https://tileserver.ivazh.ru/styles/hillshade/style.json');
            await this.showMap('gcmr_md', 'https://osm.ivazh.ru/styles/gcmr/style.json');

            this.draw.set({
                'type': 'FeatureCollection',
                'features': [
                ]
            });

            map.on('draw.create', this.onDrawFinished);

            // map.on('draw.update', (e) => {
            //     e.features.forEach((x) => {
            //         this.fillAttributes(x);
            //     });
            //     console.log(e.features);
            // });

            map.addSource('cart', {
                'type': 'geojson',
                'data': this.data
            });
            map.addLayer({
                'id': 'cart',
                'type': 'fill',
                'source': 'cart',
                'paint': {
                    'fill-color': ['case',['boolean',['feature-state','highlight'],false],'rgb(105, 0, 30)','rgb(50, 200, 105)'],
                    'fill-opacity': ['case',['boolean',['feature-state','highlight'],false],0.4,0.7]
                }
            });
        });

        map.on('mousemove', ['gcmr_mdETKOV_122111'], (e) => {
            this.highlightFeatures([this.selectFeature(e.features)]);
            this.props.selectFeature(this.feature[0]);
            this.props.clearHighlight();
        });

        map.on('mouseleave', ['gcmr_mdETKOV_122111'], () => {
            this.highlightFeatures();
            this.feature = null;
            this.props.selectFeature(null);
            this.props.clearHighlight();
        });

        map.on('click', ['gcmr_mdETKOV_122111'], (e) => {
            if (!this.clickEnabled)
                return;
            const feature = this.selectFeature(e.features);
            this.props.selected(feature, e.lngLat);
            const fs = this.features.filter((i) => i.id !== feature.id);
            if (fs.length !== this.features.length)
                this.features = fs;
            else
                this.features.push(feature);

            if (this.features.length) {
                const sids = this.features.map((i) => ['==', ['id'], i.id]);
                map.setFilter('gcmr_mdENMK_717000-selected', ['any',  ...sids]);
            } else {
                map.setFilter('gcmr_mdENMK_717000-selected', ['any',  ['==', ['id'], '']]);
            }
        });

    }

    render() {
        return (
            <Box sx={{width: '100%', height: '100%'}} ref={this.mapContainerRef} className='map-container'/>
        );
    }
}
