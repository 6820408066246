import React from 'react';
import {
    Grid,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Box,
    Card,
    ToggleButton,
    Autocomplete,
    TextField,
    Paper,
    CardContent,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Checkbox,
    ListItemIcon,
    Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, Stack
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import NorthWestIcon from '@mui/icons-material/NorthWest';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TimelineIcon from '@mui/icons-material/Timeline';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';

import {TreeItem} from "@mui/lab";
import MapReact from "./MapReact";
import {DataGrid} from "@mui/x-data-grid";
import axios from 'axios';

class GridFooter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Stack direction={"column"} spacing={1} sx={{padding:'5px'}}>
                <Button variant="contained" onClick={this.props.onClick} size={'small'} sx={{width:'90px'}}>Заказать</Button>
                <Button variant="contained" onClick={this.props.clearCart} size={'small'} sx={{width:'90px'}}>Очистить</Button>
            </Stack>
        );
    }
}

export default class Screen extends React.Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();

        this.girRef = React.createRef();
        this.listRef = React.createRef();

        this.state = {
            feature: null,
            showPreview: false,
            cart: [],
            options: [],
            data: [
                {label: 'Генеральные 1:5000000 - 1:1000000', checked: true, id: 0},
                {label: 'Путевые 1 1:750000 - 1:500000', checked: true, id: 1},
                {label: 'Путевые 2 1:500000 - 1:100000', checked: true, id: 2},
                {label: 'Частные 1:100000 - 1:25000', checked: true, id: 3},
                {label: 'Морские планы 1:25000 - 1:500', checked: true, id: 4},
            ],
            regions: false,
            girItems: [],
            girValue: null,
            listValue: null,
            listItems: [],
            map: null,
            select: 'point',
            tableSelected: [],
            open: false,
            filterOptions: [],
            filterValue: null
        }

        this.selectFeature = (feature) => {
            const state = this.state;
            state.feature = feature;
            this.setState(state);
        };

        this.showPreview = () => {
            const state = this.state;
            state.selected = !state.selected;
            if (!state.selected)
                this.mapRef.current.hidePreview();
            this.setState(state);
            this.mapRef.current.clearFeatures();
        };

        this.onSelected = (feature, latLng) => {
            const state = this.state;
            if (!state.selected)
                return;
            this.mapRef.current.showPreview(feature.id);
        };

        this.addToCart = () => {
            const map = this.mapRef.current;
            if (!map.features.length)
                return;
            const state = this.state;
            const ids = new Set(state.cart.map((i) => i.id));
            let fs = map.features.filter((i) => !ids.has(i.id));
            state.cart = state.cart.concat(fs);
            this.setState(state);
            this.mapRef.current.cartFeatures(state.cart);
            map.clearFeatures();
        }

        this.clearCart = () => {
            const state = this.state;
            state.cart = [];
            this.setState(state);
            this.mapRef.current.cartFeatures(state.cart);
        }

        this.clearSelection = () => {
            const map = this.mapRef.current;
            map.clearFeatures();
        }

        this.onClick = () => {
            console.log('click');
        }

        this.onInputChange = (event, newValue) => {
            axios
                .post('https://es.ivazh.ru/regions/_search', {
                    query: {
                        match_phrase_prefix: {
                            name: newValue
                        }
                    }
                })
                .then(res => {
                    const results = res.data.hits.hits.map(h => h._source);
                    this.setState({options: results});
                })
        }

        this.onSearchChange = (event, newValue) => {
            const map = this.mapRef.current;
            if (!newValue)
                return;
            console.log(newValue);
            map.zoomTo(newValue.lat, newValue.lon, newValue.level);
        }

        this.onFilterInputChange = (event, newValue) => {
            axios
                .post('https://es.ivazh.ru/filters/_search', {
                    query: {
                        match_phrase_prefix: {
                            name: newValue
                        }
                    }
                })
                .then(res => {
                    const results = res.data.hits.hits.map(h => h._source);
                    this.setState({filterOptions: results});
                })
        }

        this.onFilterSearchChange = (event, newValue) => {
            this.setState({filterValue: newValue});
        }

        this.onRegionStateChange = (event, value) => {
            const status = !this.state.regions;
            this.setState({regions: status});
            const map = this.mapRef.current;
            if (status) {
                map.showMap('rg', 'https://osm.ivazh.ru/styles/regions/style.json');
            } else {
                map.hideMap('rg', 'https://osm.ivazh.ru/styles/regions/style.json');
            }
        }

        this.handleGirChange = (event, value) => {
            if (!value)
                return;
            if (value.label === 'НПД')
                window.location = '/';
            if (value.label === 'ГЦМР')
                window.location = '/gcmr';
            if (value.label === 'ООБД')
                window.location = '/oodb';
        }

        this.customList = (items) => {
            return (
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {
                        items.map((value) => {
                            return (
                                <ListItem key={value.id} disablePadding>
                                    <ListItemButton role={undefined} dense onClick={this.handleToggle(value.id, value.layers)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={this.state.data[value.id].checked}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': `checkbox-list-label-${value.id}` }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={`checkbox-list-label-${value.id}`} primary={value.label} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })
                    }
                </List>
            );
        }

        this.handleListChange = (event, value) => {
            const map = this.mapRef.current;
            if (this.state.map)
                map.hideMap(this.state.map.id, this.state.map.url);
            if (value.items) {
                map.showMap('', value.url);
                this.setState({listValue: value, listItems: value.items, map: {id: '', url: value.url},
                data:[
                    {label: 'Генеральные 1:5000000 - 1:1000000', checked: true, id: 0},
                    {label: 'Путевые 1 1:750000 - 1:500000', checked: true, id: 1},
                    {label: 'Путевые 2 1:500000 - 1:100000', checked: true, id: 2},
                    {label: 'Частные 1:100000 - 1:25000', checked: true, id: 3},
                    {label: 'Морские планы 1:25000 - 1:500', checked: true, id: 4}
                ]});
            } else {
                this.setState({listValue: value, listItems: [], map: null});
            }
        }

        this.drawLine = () => {
            this.setState({select: 'line'});
            this.mapRef.current.drawLine();
        }

        this.drawPolygon = () => {
            this.setState({select: 'polygon'});
            this.mapRef.current.drawPolygon();
        }

        this.drawFinished = () => {
            this.setState({select: 'point'});
        }

        this.highlightFeature = (items) => {
            this.setState({tableSelected: items});
            const sitems = new Set(items);
            let features = this.state.cart.filter((x) => sitems.has(x.id));
            if (features.length) {
                this.mapRef.current.highlightFeatures(features);
            } else {
                this.mapRef.current.highlightFeatures();
            }
        }

        this.clearHighlight = () => {
            this.setState({tableSelected: []});
        }

        this.handleClose = () => {
            this.setState({open: false});
            this.mapRef.current.filterRegion(this.state.filterValue);
        }

        this.handleClear = () => {
            this.setState({open: false});
            this.mapRef.current.filterRegion(null);
        }

        this.handleDialog = () => {
            this.setState({open: true});
        }
    }

    girTypes = [
        {
            label: 'НПД',
            items: [
                {
                    label: '(717000) Электронные навигационные морские карты',
                    url: 'https://osm.ivazh.ru/styles/catalog/style.json',
                    items: [
                        {
                            label: '717000', checked: true, id: 0, layers: [
                                'ENMK_717000', 'ENMK_717000-selected', 'ENMK_717000-border', 'ENMK_717000-label'
                            ]
                        }
                    ]
                },
                {
                    label: '(711000) Электронные навигационные карты',
                    url: 'https://osm.ivazh.ru/styles/seamaps/style.json',
                    items: [
                        {
                            label: 'Генеральные 1:5000000 - 1:1000000', checked: true, id: 0, layers: [
                                'ENMK_717000_0', 'ENMK_717000_0-border', 'ENMK_717000_0-label'
                            ]
                        },
                        {
                            label: 'Путевые 1 1:750000 - 1:500000', checked: true, id: 1, layers: [
                                'ENMK_717000_1', 'ENMK_717000_1-border', 'ENMK_717000_1-label'
                            ]
                        },
                        {
                            label: 'Путевые 2 1:500000 - 1:100000', checked: true, id: 2, layers: [
                                'ENMK_717000_2', 'ENMK_717000_2-border', 'ENMK_717000_2-label'
                            ]
                        },
                        {
                            label: 'Частные 1:100000 - 1:25000', checked: true, id: 3, layers: [
                                'ENMK_717000_3', 'ENMK_717000_3-border', 'ENMK_717000_3-label'
                            ]
                        },
                        {
                            label: 'Морские планы 1:25000 - 1:500', checked: true, id: 4, layers: [
                                'ENMK_717000_4', 'ENMK_717000_4-border', 'ENMK_717000_4-label'
                            ]
                        },
                    ]
                },
                {
                    label: '(122111) Электронные топографические карты - отечественные - векторные',
                    url: 'https://osm.ivazh.ru/styles/topo/style.json',
                    items: [
                        {
                            label: 'Топографические карты 1:50000', checked: true, id: 0, layers: [
                                'ETKOV_122111', 'ETKOV_122111-border', 'ETKOV_122111-selected', 'ETKOV_122111-label'
                            ]
                        }
                    ]
                },
                { label: '(100000) Геопространственная информация' },
                { label: '(110000) Цифровые астрономо-геодезические и гравиметрические данные' },
                { label: '(111000) Справочные астрономо-геодезические и гравиметрические данные' },
                { label: '(111100) Цифровые каталоги (списки) пунктов космической геодезической сети' },
                { label: '(111200) Цифровые каталоги (списки) астрономических пунктов' },
                { label: '(111300) Цифровые каталоги (списки) геодезических пунктов' },
                { label: '(111400) Цифровые каталоги (списки) гравиметрических пунктов' },
                { label: '(111500) Цифровые каталоги (списки) высот пунктов нивелирования' },
                { label: '(111600) Цифровые каталоги (списки) средних значений аномалий силы тяжести в свободном воздухе и высот' },
                { label: '(112000) Цифровые карты с астрономо-геодезическими и гравиметрическими данными' },
                { label: '(112100) Цифровые карты высот квазигеоида над эллипсоидом Красовского' },
                { label: '(112200) Цифровые карты высот квазигеоида над общеземным эллипсоидом' },
                { label: '(113000) Электронные карты с астрономо-геодезическими и гравиметрическими данными' },
                { label: '(113100) Электронные гравиметрические карты' },
                { label: '(113200) Электронные карты высот квазигеоида над эллипсоидом Красовского' },
                { label: '(113300) Электронные карты высот квазигеоида над общеземным эллипсоидом' },
                { label: '(113400) Электронные карты уклонений отвесных линий' },
                { label: '(114000) Цифровые модели с астрономо-геодезическими и гравиметрическими данными' },
                { label: '(114100) Локальные модели гравитационного поля Земли' },
                { label: '(114200) Глобальные (планетарные) модели гравитационного поля Земли' },
                { label: '(114300) Региональные модели гравитационного поля Земли' },
                { label: '(114400) Цифровые модели уклонений отвесных линий' },
                { label: '(114500) Цифровые модели аномалий силы тяжести' },
                { label: '(114600) Цифровые модели высот геоида' },
                { label: '(120000) Цифровая картографическая информация' },
                { label: '(121000) Цифровые карты и планы' },
                { label: '(121100) Цифровые топографические карты' },
                { label: '(121110) Цифровые топографические карты - векторные' },
                { label: '(121120) Цифровые топографические карты - растровые' },
                { label: '(121200) Цифровые планы городов' },
                { label: '(121210) Цифровые планы городов - векторные' },
                { label: '(121220) Цифровые планы городов - растровые' },
                { label: '(121300) Цифровые навигационные карты' },
                { label: '(121310) Открытые цифровые навигационные карты' },
                { label: '(121400) Цифровые топографические планы' },
                { label: '(121410) Цифровые топографические планы - векторные' },
                { label: '(121420) Цифровые топографические планы - растровые' },
                { label: '(122000) Электронные карты' },
                { label: '(122100) Электронные топографические карты' },
                { label: '(122110) Электронные топографические карты  - отечественные' },
                { label: '(122112) Электронные топографические карты - отечественные - растровые' },
                { label: '(122113) "Cшитые" наборы ЭТК, векторные' },
                { label: '(122114) «Cшитые» наборы ЭТК, растровые' },
                { label: '(122119) Электронные топографические карты открытого пользования (ЭТК ОП)' },
                { label: '(122120) Электронные карты - зарубежные' },
                { label: '(122121) Электронные карты - зарубежные - векторные' },
                { label: '(122122) Электронные карты - зарубежные - растровые' },
                { label: '(122200) Электронные специальные карты' },
                { label: '(122210) Электронные планы городов - отечественные' },
                { label: '(122211) Электронные планы городов - отечественные - векторные' },
                { label: '(122212) Электронные планы городов - отечественные - растровые' },
                { label: '(122220) Электронные планы городов - зарубежные' },
                { label: '(122221) Электронные планы городов - зарубежные - векторные' },
                { label: '(122222) Электронные планы городов - зарубежные - растровые' },
                { label: '(122230) Электронные обзорно-географические карты' },
                { label: '(122231) Электронные обзорно-географические карты - векторные' },
                { label: '(122232) Электронные обзорно-географические карты - растровые' },
                { label: '(122240) Электронные авиационные карты' },
                { label: '(122241) Электронные авиационные карты - векторные' },
                { label: '(122242) Электронные авиационные карты - растровые' },
                { label: '(122243) Электронные авиационные карты с сеткой ПВО (ЭАК-ПВО)' },
                { label: '(122244) Электронные авиационные карты - бланковые (ЭАК-Б)' },
                { label: '(122247) Электронные космонавигационные карты (ЭКНК)' },
                { label: '(122250) Электронные морские карты' },
                { label: '(122251) Электронные навигационные карты - векторные' },
                { label: '(122252) Электронные морские карты - векторные' },
                { label: '(122260) Электронные карты изменений местности - векторные' },
                { label: '(122270) Слои изменений местности в формате sit - векторные' },
                { label: '(122280) Электронные карты рельефа' },
                { label: '(123000) Цифровые модели' },
                { label: '(123100) Цифровые карты местности' },
                { label: '(123200) Цифровые пространственные модели местности' },
                { label: '(123300) Специальные высокоточные трехмерные электронные карты местности и подземных коммуникаций' },
                { label: '(123400) Цифровые модели издательских оригиналов карт' },
                { label: '(123410) Цифровые модели издательских оригиналов топографических карт' },
                { label: '(123420) Цифровые модели издательских оригиналов обзорно-географических карт' },
                { label: '(123430) Цифровые модели издательских оригиналов авиационных карт' },
                { label: '(123440) Цифровые модели издательских оригиналов планов городов' },
                { label: '(123500) Комплект унифицированных векторных слоёв' },
                { label: '(123600) Тематические векторные слои' },
                { label: '(123700) Трёхмерная модель местности и объектов' },
                { label: '(123710) ТММО цифровой пространственной модели местности' },
                { label: '(123720) ТММО трехмерные объекты местности' },
                { label: '(123730) Трехмерные модели объектов и образцов ВВСТ потребителей РВСН' },
                { label: '(123800) Тематические информационные слои' },
                { label: '(123810) Тематические информационные слои базовых элементов глобального пассивного навигационного поля' },
                { label: '(124000) Исходная цифровая информация для создания электронных карт, цифровых фотодокументов, цифровых моделей' },
                { label: '(124100) Электронные карты  рельефа – планово-высотная основа' },
                { label: '(124200) Исходная растровая информация для создания электронных топографических карт' },
                { label: '(124300) Исходная растровая информация для создания электронных планов городов' },
                { label: '(124400) Исходная растровая информация для создания электронных обзорно-географических  карт' },
                { label: '(124500) Исходная растровая информация для создания электронных авиационных карт' },
                { label: '(124600) Исходная растровая информация для создания ЦКМ' },
                { label: '(126000) Единая система государственных электронных карт РФ' },
                { label: '(126100) Подсистема электронных топографических карт' },
                { label: '(126200) Подсистема электронных обзорных карт' },
                { label: '(129000) Электронные карты повышенной точности' },
                { label: '(129100) Электронные топографические карты повышенной точности' },
                { label: '(129200) Электронные планы городов (населенных пунктов) повышенной точности' },
                { label: '(129300) Электронные обзорно-географические карты повышенной точности' },
                { label: '(129400) Электронные авиационные карты повышенной точности' },
                { label: '(129500) Электронные навигационные карты повышенной точности' },
                { label: '(130000) Материалы и данные дистанционного зондирования Земли' },
                { label: '(131000) Материалы аэросъемки' },
                { label: '(131100) Оцифрованные аэроснимки' },
                { label: '(131200) Цифровые аэроснимки' },
                { label: '(132000) Материалы космических съемок' },
                { label: '(132100) Оцифрованные топографические снимки' },
                { label: '(132200) Оцифрованные дешифровочные снимки' },
                { label: '(132300) Оцифрованные звезные снимки' },
                { label: '(132400) Цифровые изображения местности' },
                { label: '(133000) Результаты работы специальной аппаратуры и другие данные, сопровождающие материалы космических съемок' },
                { label: '(134000) Материалы специальных съемок' },
                { label: '(135000) Материалы космической съемки' },
                { label: '(140000) Цифровая фотограмметрическая информация' },
                { label: '(141000) Цифровые ортофотоизображения' },
                { label: '(141100) Матрицы плановых изображений' },
                { label: '(141110) Основные матрицы плановых изображений' },
                { label: '(141120) Контрольные матрицы плановых изображений' },
                { label: '(141130) Опорные матрицы плановых изображений' },
                { label: '(141200) Опорные бесшовные изображения' },
                { label: '(141300) Цифровые фотопланы (ортофотопланы)' },
                { label: '(141310) Цифровые фотопланы (ортофотопланы)' },
                { label: '(141320) Опорные цифровые фотопланы (ортофотопланы)' },
                { label: '(141330) Базовое цифровое растровое покрытие высокого разрешения' },
                { label: '(141340) Цифровое растровое покрытие детального разрешения' },
                { label: '(141350) Цифровое растровое покрытие среднего разрешения' },
                { label: '(141400) Электронные фотокарты' },
                { label: '(141500) Цифровые фотосхемы' },
                { label: '(141600) Цифровые фотоснимки с координатной сеткой' },
                { label: '(142000) Цифровые модели рельефа' },
                { label: '(142100) Цифровые матрицы рельефа' },
                { label: '(142400) Унифицированная цифровая модель местности – цифровая модель рельефа' },
                { label: '(142600) Унифицированная трехмерная цифровая модель рельефа морского дна' },
                { label: '(142700) Наборы данных глобального рельефа местности (НД ГРМ)' },
                { label: '(147000) Бесшовные изображения (БИ)' },
                { label: '(147300) Наборы данных глобального растрового покрытия ДЗЗ' },
                { label: '(147400) Наборы данных растровых мультимасштабных карт' },
                { label: '(147500) Наборы данных векторных мультимасштабных карт' },
                { label: '(150000) Справочная текстово-табличная информация' },
                { label: '(151000) Справки о местности к номенклатурным листам ЭТК масштаба 1:200000' },
                { label: '(152000) Справки к электронным планам городов' },
                { label: '(160000) Электронные атласы' },
                { label: '(170000) Электронные глобусы' },
                { label: '(180000) Электронные сборные таблицы' },
                { label: '(181000) Электронные сборные таблицы - векторные' },
                { label: '(182000) Электронные сборные таблицы - растровые' },
                { label: '(190000) Электронные графические документы' },
                { label: '(191000) Электронные графические документы - векторные' },
                { label: '(192000) Электронные графические документы - растровые' },
                { label: '(600000) Сопровождающие информационные документы' },
                { label: '(600100) Условно-постоянная информация' },
                { label: '(600110) Условно-постоянная информация для БУК ВГПИ' },
                { label: '(600120) Условно-постоянная информация для комплекта СК ВГПИ ВКС' },
                { label: '(610000) Объектно-ориентированные базы пространственных данных (ООБ ПД)' },
                { label: '(611000) Объектно-ориентированная база базовых пространственных объектов (ООБ БПО)' },
                { label: '(700000) Морские карты ВМФ' },
                { label: '(710000) Электронные морские карты' },
                { label: '(712000) Электронные информационно-справочные карты' },
                { label: '(713000) Электронные карты внутренних водных путей' },
                { label: '(714000) Электронные батиметрические карты' },
                { label: '(715000) Электронные морские карты специального назначения' },
                { label: '(716000) Электронные обзорно-географические морские карты' },
                { label: '(720000) Цифровые морские карты' },
                { label: '(721000) Цифровые навигационные морские карты' },
                { label: '(721100) Цифровые общенавигационные карты' },
                { label: '(721200) Цифровые радионавигационные карты и радионавигационные планшеты' },
                { label: '(721300) Цифровые навигационно-промысловые карты' },
                { label: '(722000) Цифровые морские вспомогательные и справочные карты 1' },
                { label: '(722100) Цифровые справочно-информационные карты' },
                { label: '(722200) Цифровые бланковые морские карты' },
                { label: '(722300) Цифровые карты-сетки' },
                { label: '(722400) Цифровые карты для прокладки ортодромии' },
                { label: '(722500) Цифровые карты для спасательных средств' },
                { label: '(722600) Цифровые карты с наглядными изображениями рельефа морского дна' },
                { label: '(722700) Цифровые батиметрические карты' },
                { label: '(722800) Цифровые карты подводных кабелей' },
                { label: '(722900) Цифровые карты часовых поясов' },
                { label: '(723000) Цифровые морские вспомогательные и справочные карты 2' },
                { label: '(723100) Цифровые карты районов боевой подготовки' },
                { label: '(723200) Цифровые карты памятных мест побед и героической гибели кораблей русского флота' },
                { label: '(723300) Цифровые карты для яхт и прогулочных катеров' },
                { label: '(730000) Цифровые карты внутренних водных путей' },
                { label: '(731000) Цифровые навигационные карты судоходных озер, устьевых и предустьевых участков рек' },
                { label: '(732000) Цифровые навигационно-речные карты' },
                { label: '(740000) Цифровые информационно-справочные карты' },
                { label: '(750000) Цифровые обзорно-географические карты' },
                { label: '(760000) Ортофотопланы на акватории Мирового океана' },
                { label: '(800000) Руководства и пособия ВМФ' },
                { label: '(810000) Цифровые морские руководства и пособия' },
                { label: '(811000) Морские навигационные руководства и пособия' },
                { label: '(811100) Руководства для плавания' },
                { label: '(811110) Лоции и приложения к ним' },
                { label: '(811120) Огни и знаки' },
                { label: '(811130) Радиотехнические средства навигационного оборудования' },
                { label: '(811140) Расписания передач навигационных предупреждений и гидрометеорологических сообщений радиостанциями' },
                { label: '(811150) Расписания факсимильных гидрометеорологических передач' },
                { label: '(811160) Режим плавания судов (сводные описания)' },
                { label: '(811170) Правила плавания' },
                { label: '(811180) Руководства для захода судов в российские порты' },
                { label: '(811190) Руководства для плавания во льдах по Северному морскому пути' },
                { label: '(811200) Пособия для плавания' },
                { label: '(811210) Океанские пути мира' },
                { label: '(811220) Таблицы радионавигационных систем' },
                { label: '(811230) Атласы зон точности определения места с помощью РНС' },
                { label: '(811300) Морские гидрометеорологические пособия' },
                { label: '(811310) Атласы океанографических параметров' },
                { label: '(811320) Комплекты гидрометерологических карт морей и районов океанов' },
                { label: '(811330) Атласы льдов' },
                { label: '(811340) Атласы приливных течений' },
                { label: '(811350) Таблицы приливов и приливных течений' },
                { label: '(811360) Атласы опасных и особо опасных гидрометерологических явлений' },
                { label: '(811400) Морские астрономические пособия' },
                { label: '(811410) Морской астрономический ежегодник' },
                { label: '(811420) Астрономический альманах' },
                { label: '(811430) Таблицы вычисления высот и азимутов' },
                { label: '(811440) Таблицы Высота и азимут светил' },
                { label: '(811450) Другие морские астрономические пособия' },
                { label: '(811500) Международно-правовые руководства' },
                { label: '(811510) Морское законодательство Российской Федерации' },
                { label: '(811520) Сборник международных договоров и законодательных актов по вопросам плавания' },
                { label: '(811530) Международные правила предупреждения столкновения судов в море' },
                { label: '(811540) Руководство Пределы действия территориальной юрисдикции государств в Мировом океане' },
                { label: '(811600) Вспомогательные и справочные пособия для мореплавателей' },
                { label: '(811610) Извещения мореплавателям' },
                { label: '(811620) Бюллетени навигационных предупреждений' },
                { label: '(811630) Международный свод сигналов' },
                { label: '(811640) Общие положения об установлении путей движения судов' },
                { label: '(811650) Описание системы плавучего ограждения в водах России. Система МАМС, регион А' },
                { label: '(811660) Описание особенностей огней военных кораблей и сигналов, подаваемых кораблями и судами для обеспечения безопасности плавания' },
                { label: '(811670) Таблицы морских расстояний' },
                { label: '(811680) Мореходные таблицы' },
                { label: '(811690) Каталоги карт и книг' },
                { label: '(812000) Специальные пособия для ВМФ' },
                { label: '(813000) Морские промысловые пособия' },
                { label: '(813100) Промысловые описания' },
                { label: '(813200) Описания надводных гор и поднятий морского дна в промысловых районах Мирового океана' },
                { label: '(814000) Руководства и пособия для внутренних водных путей' },
                { label: '(815000) Уставные документы ГС ВМФ' },
                { label: '(816000) Руководящие документы, производственно-технические руководства и пособия' },
                { label: '(817000) Фундаментальные пособия по гидрографии морей и океанов' },
                { label: '(817100) Атлас океанов' },
                { label: '(818000) Научно-технические и учебные пособия' },
                { label: '(818100) Научные труды и монографии по навигации, гидрографии и океанографии' },
                { label: '(818200) Учебные пособия для гидрографов и штурманов' },
                { label: '(818300) Военно-научные издания' },
                { label: '(818400) Периодические научно-технические издания' },
                { label: '(820000) Электронные морские руководства и пособия' },
                { label: '(821000) Морские навигационные руководства и пособия' },
                { label: '(821100) Руководства для плавания' },
                { label: '(821110) Лоции и приложения к ним' },
                { label: '(821120) Огни и знаки' },
                { label: '(821130) Радиотехнические средства навигационного оборудования' },
                { label: '(821140) Расписания передач навигационных предупреждений и гидрометеорологических сообщений радиостанциями' },
                { label: '(821150) Расписания факсимильных гидрометеорологических передач' },
                { label: '(821160) Режим плавания судов (сводные описания)' },
                { label: '(821170) Правила плавания' },
                { label: '(821180) Руководства для захода судов в российские порты' },
                { label: '(821190) Руководства для плавания во льдах по Северному морскому пути' },
                { label: '(821200) Пособия для плавания' },
                { label: '(821210) Океанские пути мира' },
                { label: '(821220) Таблицы радионавигационных систем' },
                { label: '(821230) Атласы зон точности определения места с помощью РНС' },
                { label: '(821300) Морские гидрометеорологические пособия' },
                { label: '(821310) Атласы океанографических параметров' },
                { label: '(821320) Комплекты гидрометерологических карт морей и районов океанов' },
                { label: '(821330) Атласы льдов' },
                { label: '(821340) Атласы приливных течений' },
                { label: '(821350) Таблицы приливов и приливных течений' },
                { label: '(821360) Атласы опасных и особо опасных гидрометерологических явлений' },
                { label: '(821400) Морские астрономические пособия' },
                { label: '(821410) Морской астрономический ежегодник' },
                { label: '(821420) Астрономический альманах' },
                { label: '(821430) Таблицы вычисления высот и азимутов' },
                { label: '(821440) Таблицы Высота и азимут светил' },
                { label: '(821450) Другие морские астрономические пособия' },
                { label: '(821500) Международно-правовые руководства' },
                { label: '(821510) Морское законодательство Российской Федерации' },
                { label: '(821520) Сборник международных договоров и законодательных актов по вопросам плавания' },
                { label: '(821530) Международные правила предупреждения столкновения судов в море' },
                { label: '(821540) Руководство Пределы действия территориальной юрисдикции государств в Мировом океане' },
                { label: '(821600) Вспомогательные и справочные пособия для мореплавателей' },
                { label: '(821610) Базы навигационных и гидрографических данных' },
                { label: '(821611) БД электронной системы извещений мореплавателям (ЭСИМ)' },
                { label: '(821620) Международный свод сигналов' },
                { label: '(821630) Общие положения об установлении путей движения судов' },
                { label: '(821640) Описание системы плавучего ограждения в водах России. Система МАМС, регион А' },
                { label: '(821650) Описание особенностей огней военных кораблей и сигналов, подаваемых кораблями и судами для обеспечения безопасности плавания' },
                { label: '(821660) Таблицы морских расстояний' },
                { label: '(821670) Мореходные таблицы' },
                { label: '(821680) Каталоги карт и книг' },
                { label: '(822000) Специальные пособия для ВМФ' },
                { label: '(823000) Морские промысловые пособия' },
                { label: '(823100) Промысловые описания' },
                { label: '(823200) Описания надводных гор и поднятий морского дна в промысловых районах Мирового океана' },
                { label: '(824000) Руководства и пособия для внутренних водных путей' },
                { label: '(825000) Уставные документы ГС ВМФ' },
                { label: '(826000) Руководящие документы, производственно-технические руководства и пособия' },
                { label: '(827000) Фундаментальные пособия по гидрографии морей и океанов' },
                { label: '(827100) Атлас океанов' },
                { label: '(828000) Научно-технические и учебные пособия' },
                { label: '(828100) Научные труды и монографии по навигации, гидрографии и океанографии' },
                { label: '(828200) Учебные пособия для гидрографов и штурманов' },
                { label: '(828300) Военно-научные издания' },
                { label: '(828400) Периодические научно-технические издания' },
                { label: '(830000) Специальные Базы данных' },
                { label: '(831000) Базы морских внемасштабных объектов' },
                { label: '(832000) Батиметрические базы' },
                { label: '(833000) Базы пространственных данных на акватории Мирового океана' },
                { label: '(834000) Базы данных электронной системы извещений мореплавателям' },
                { label: '(900000) Временные объекты ГПИ' },
                { label: '(922000) Электронные карты' },
                { label: '(922100) Электронные топографические карты' },
                { label: '(922110) Электронные топографические карты  - отечественные' },
                { label: '(922111) Электронные топографические карты - отечественные - векторные' },
            ]
        },
        { label: 'ЕЦПЗ' },
        { label: 'ГЦМР' },
        { label: 'ООБД' }
    ];

    renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => this.renderTree(node))
                : null}
        </TreeItem>
    );

    checked = [];

    handleToggle = (value, layers) => () => {
        const data = this.state.data;
        data[value].checked = !data[value].checked;
        this.setState({data: data});
        const map = this.mapRef.current.mapRef.current;
        layers.map((item) => {
            map.setLayoutProperty(item, 'visibility', data[value].checked ? 'visible': 'none');
        });
    };

    columns = [
        {field: 'id', headerName: 'ID', sortable: true},
        {field: 'c122', headerName: 'Наименование', flex: 1, sortable: true, valueGetter: (params) => `${params.row.properties.c122 || ''}`},
        {field: 'c202', headerName: 'Номенклатура', flex: 1, sortable: true, valueGetter: (params) => `${params.row.properties.c202 || ''}`},
        {field: 'c103', headerName: 'Секретность', flex: 1, sortable: true, valueGetter: (params) => `${params.row.properties.c103 || ''}`},
        {field: 'c201', headerName: 'Масштаб', sortable: true, valueGetter: (params) => `1 : ${params.row.properties.c201}`},
        {field: 'c236', headerName: 'Тип карты', width: 150, sortable: true, valueGetter: (params) => `${params.row.properties.c236}`},
    ];

    rows = [];

    render() {
        return (
            <Grid container>
                <Grid container xs sx={{ height: '100vh' }} direction={"column"}>
                    <Grid item xs sx={{ height: 'calc(100vh - 64px)' }}>
                        <AppBar position={"static"} color={'default'}>
                            <Toolbar variant={"regular"}>
                                <Autocomplete
                                    disablePortal
                                    size={"small"}
                                    id="combo-box-demo"
                                    options={this.girTypes}
                                    value={this.girTypes[0]}
                                    onChange={this.handleGirChange}
                                    sx={{ flexGrow: 1, flexDirection: 'row' }}
                                    renderInput={(params) => <TextField {...params} label="Вид ГИР" />}
                                />
                            </Toolbar>
                        </AppBar>
                        <Box sx={{padding:2}} >
                            <Autocomplete
                                disablePortal
                                size={"small"}
                                id="combo-box-demo"
                                options={this.girTypes[0].items}
                                value={this.state.listValue}
                                onChange={this.handleListChange}
                                sx={{ flexGrow: 1, flexDirection: 'row' }}
                                renderInput={(params) => <TextField {...params} label="" />}
                            />
                            {this.customList(this.state.listItems)}
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Card sx={{ height:'calc(100% - 32px)', padding:2 }}>
                            <Typography align={"center"}>Информация</Typography>
                            <Typography>
                                Наименование: {this.state.feature ? this.state.feature.properties.c122 : ''}
                            </Typography>
                            <Typography>
                                Номенклатура: {this.state.feature ? this.state.feature.properties.c202 : ''}
                            </Typography>
                            <Typography>
                                 Масштаб: {this.state.feature ? '1:' + this.state.feature.properties.c201 : ''}
                            </Typography>
                            <Typography>
                                 Секретность: {this.state.feature ? this.state.feature.properties.c103 : ''}
                            </Typography>
                            <Typography>
                                 Регион: {this.state.feature ? this.state.feature.properties.c234 : ''}
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container xs={10} sx={{ height: '100vh' }} direction={"column"}>
                    <Grid item xs sx={{height: 'calc(100vh - 64px)'}}>
                        <AppBar position={'sticky'} color={'default'}>
                            <Toolbar variant={"regular"}>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    selected={this.state.regions}
                                    onChange={this.onRegionStateChange}
                                    sx={{ mr: 1 }}
                                >
                                    <SouthAmericaIcon />
                                </ToggleButton>
                                <Autocomplete
                                    autoComplete
                                    disablePortal
                                    size={"small"}
                                    options={this.state.options}
                                    getOptionLabel={(option) =>
                                        typeof option === 'string' ? option : option.full_name
                                    }
                                    sx={{ width: 350, mr: 1 }}
                                    filterOptions={(x) => x}
                                    onChange={this.onSearchChange}
                                    onInputChange={this.onInputChange}
                                    renderInput={(params) => <TextField {...params} label="Адресный поиск" />}
                                />
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 4 }}
                                    onClick={this.handleDialog}
                                >
                                    <FilterAltIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    selected={this.state.select === 'point'}
                                    title={'Выбрать'}
                                >
                                    <NorthWestIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    title={'Выбрать по линии'}
                                    selected={this.state.select === 'line'}
                                    onChange={this.drawLine}
                                >
                                    <TimelineIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    selected={this.state.select === 'polygon'}
                                    title={'Выбрать по полигону'}
                                    onChange={this.drawPolygon}
                                >
                                    <CropSquareIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    onChange={this.showPreview}
                                    selected={this.state.selected}
                                    title={'Предпросмотр'}
                                >
                                    <VisibilityIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    onChange={this.addToCart}
                                    title={'Добавить в корзину'}
                                >
                                    <AddShoppingCartIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value={"check"}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    onChange={this.clearSelection}
                                    title={'Очистить'}
                                >
                                    <ClearIcon />
                                </ToggleButton>
                            </Toolbar>
                        </AppBar>
                        <Box sx={{height:'calc(100% - 64px)'}}>
                            <MapReact clearHighlight={this.clearHighlight} selectFeature={this.selectFeature} drawFinished={this.drawFinished} selected={this.onSelected} ref={this.mapRef}/>
                            <Dialog open={this.state.open} onClose={this.handleClose} >
                                <DialogTitle>Фильтр по регионам</DialogTitle>
                                <DialogContent sx={{height:'500px', width: '500px'}}>
                                    <DialogContentText>
                                        Выберите регион с помощью поля ввода
                                    </DialogContentText>
                                    <Autocomplete
                                        autoComplete
                                        disablePortal
                                        size={"small"}
                                        options={this.state.filterOptions}
                                        getOptionLabel={(option) =>
                                            typeof option === 'string' ? option : option.full_name
                                        }
                                        sx={{ mr: 1, mt: 1 }}
                                        filterOptions={(x) => x}
                                        onChange={this.onFilterSearchChange}
                                        onInputChange={this.onFilterInputChange}
                                        renderInput={(params) => <TextField {...params} label="Фильтр" />}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose}>Выбрать</Button>
                                    <Button onClick={this.handleClear}>Отмена</Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container direction={"row"} sx={{height:'100%'}}>
                            <Grid item xs>
                                <DataGrid
                                    columns={this.columns} rows={this.state.cart}
                                    density={'compact'}
                                    editMode={"row"}
                                    onSelectionModelChange={this.highlightFeature}
                                    checkboxSelection={true}
                                    selectionModel={this.state.tableSelected}
                                    pageSize={100}
                                />
                            </Grid>
                            <Grid item xs={'10px'}>
                                <GridFooter onClick={this.onClick} clearCart={this.clearCart}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
