import React from 'react';
import mapboxgl from 'mapbox-gl';
import Box from "@mui/material/Box";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';

mapboxgl.config.API_URL = 'https://react.ivazh.ru'
mapboxgl.accessToken = 'pk';


class MapReact extends React.Component {
    constructor(props) {
        super(props);
        this.mapContainerRef = React.createRef();
        this.mapRef = React.createRef();
        this.state = {
            lat: 0,
            lng: 0,
            zoom: 0
        }
        this.clickEnabled = true;

        this.showPreview = async (id) => {
            await this.loadPreviewMap(id);
        }

        this.hidePreview = async () => {
            await this.hidePreviewMap();
        }

        this.selectFeatures = (ids) => {
            const map = this.mapRef.current;
            const sids = ids.map((i) => ['==', ['id'], i]);
            if (sids.length)
                map.setFilter('ENMK_717000-highlighted', ['any', ...sids]);
            else
                map.setFilter('ENMK_717000-highlighted', ['any', ['==', ['id'], '']]);
        }

        this.getStyle = async (url) => {
            const query = await fetch(url, {method: 'GET'});
            return await query.json();
        }

        this.hidePreviewMap = async () => {
            const data = await this.getStyle(`https://tsgl.ivazh.ru/styles/ENMK_717000_100/style.json`);
            const map = this.mapRef.current;
            Object.entries(data.sources).forEach(([key, _]) => {
                if (map.getSource(key)) {
                    data.layers.forEach((layer) => {
                        map.removeLayer('717000-' + layer.id);
                    });
                    map.removeSource(key);
                }
            });
        }

        this.loadPreviewMap = async (id) => {
            const data = await this.getStyle(`https://tsgl.ivazh.ru/styles/ENMK_717000_100/${id}/style.json`);
            const map = this.mapRef.current;
            Object.entries(data.sources).forEach(([key, value]) => {
                if (map.getSource(key)) {
                    data.layers.forEach((layer) => {
                        map.removeLayer('717000-' + layer.id);
                    });
                    map.removeSource(key);
                }
                map.addSource(key, value);
            });
            data.layers.forEach((layer) => {
                layer.id = '717000-' + layer.id;
                map.addLayer(layer);
            });
        };

        this.feature = null;
        this.selectFeature = (features) => {
            let feature = null;
            if (features.length > 0) {
                feature = features[0]
                features.forEach(function (f) {
                    if (parseInt(f.properties.c201) < parseInt(feature.properties.c201)) {
                        feature = f
                    }
                });
            }
            return feature;
        }

        this.features = [];

        this.clearFeatures = () => {
            this.features = [];
            const map = this.mapRef.current;
            map.setFilter('ENMK_717000-selected', ['any',  ['==', ['id'], '']]);
        }

        this.zoomTo = (lon, lat, zoom) => {
            this.mapRef.current.flyTo({center: [lat, lon], zoom: zoom});
        }

        this.showMap = async (id, url) => {
            const data = await this.getStyle(url);
            const map = this.mapRef.current;
            Object.entries(data.sources).forEach(([key, value]) => {
                map.addSource(`${id}${key}`, value);
            });
            data.layers.forEach((layer) => {
                layer.source = `${id}${layer.source}`;
                layer.id = `${id}${layer.id}`;
                map.addLayer(layer);
            });
        }

        this.hideMap = async (id, url) => {
            const data = await this.getStyle(url);
            const map = this.mapRef.current;
            data.layers.forEach((layer) => {
                map.removeLayer(`${id}${layer.id}`);
            });
            Object.entries(data.sources).forEach(([key, _]) => {
                if (map.getSource(`${id}${key}`)) {
                    map.removeSource(`${id}${key}`);
                }
            });
        }

        this.drawLine = () => {
            if (!this.draw)
                return;
            this.draw.deleteAll();
            this.draw.changeMode('draw_line_string');
            this.clickEnabled = false;
        }

        this.drawPolygon = () => {
            if (!this.draw)
                return;
            this.draw.deleteAll();
            this.draw.changeMode('draw_polygon');
            this.clickEnabled = false;
        }

        this.onDrawFinished = (e) => {
            const layers = ['ENMK_717000','ENMK_717000_0','ENMK_717000_1','ENMK_717000_2','ENMK_717000_3','ENMK_717000_4','ETKOV_122111']
                .filter((l) => this.mapRef.current.getLayer(l));
            let features = this.mapRef.current.queryRenderedFeatures({ layers: layers });
            features = features.filter((x) => turf.booleanIntersects(e.features[0], x));
            let feature_map = new Map();
            features.forEach((item) => feature_map.set(item.id, item));
            this.features = [...feature_map.values()];
            const ids = this.features.map(x => x.id);
            const sids = ids.map((i) => ['==', ['id'], i]);
            if (sids.length)
                this.mapRef.current.setFilter('ENMK_717000-selected', ['any', ...sids]);
            else
                this.mapRef.current.setFilter('ENMK_717000-selected', ['any', ['==', ['id'], '']]);

            this.props.drawFinished();
            setTimeout(() => {this.clickEnabled = true;}, 500);
            setTimeout(() => {this.draw.deleteAll();}, 1500);
        }

        this.highlightFeatures = (f = null) => {
            const map = this.mapRef.current;
            if (this.feature) {
                this.feature.forEach((x)=> {
                    map.setFeatureState({
                        source: 'pdim',
                        sourceLayer: x.layer['source-layer'],
                        id: x.id
                    }, {
                        highlight: false
                    });
                });
            }
            this.feature = f;
            if (this.feature) {
                this.feature.forEach((x)=>{
                    map.setFeatureState({
                        source: 'pdim',
                        sourceLayer: x.layer['source-layer'],
                        id: x.id
                    }, {
                        highlight: true
                    });
                });
            }
        }

        this.filterRegion = (f) => {
            const map = this.mapRef.current;
            if (f) {
                console.log(f);
                map.setFilter('ETKOV_122111', ['==', ['get', 'c234'], f.region]);
            } else {
                map.setFilter('ETKOV_122111', null);
            }
        }

        this.cartFeatures = (features) => {
            const sids = features.map((x) => ['==', ['id'], x.id]);
            if (sids.length)
                this.mapRef.current.setFilter('ENMK_717000-filter', ['any', ...sids]);
            else
                this.mapRef.current.setFilter('ENMK_717000-filter', ['any', ['==', ['id'], '']]);

        }
    }

    componentDidMount() {
        const { lng, lat, zoom } = this.state;
        const map = new mapboxgl.Map({
            container: this.mapContainerRef.current,
            style: 'https://osm.ivazh.ru/styles/basic/style.json',
            center: [lng, lat],
            zoom: zoom,
            doubleClickZoom: false
        });
        this.mapRef.current = map;

        map.addControl(new mapboxgl.NavigationControl(), 'top-right');
        map.addControl(new mapboxgl.ScaleControl(), 'bottom-right');
        map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
        }));

        this.draw = new MapboxDraw();

        map.on('load', async () => {
            map.addControl(this.draw);
            // const data = await this.getStyle('https://osm.ivazh.ru/styles/catalog/style.json');
            // Object.entries(data.sources).forEach(([key, value]) => {
            //     map.addSource(key, value);
            // });
            // data.layers.forEach((layer) => {
            //     map.addLayer(layer);
            // });
            // map.addLayer({
            //     'id': 'ENMK_717000-highlighted',
            //     'type': 'fill',
            //     'source': 'selected',
            //     'paint': {
            //         'fill-outline-color': '#484896',
            //         'fill-color': '#6e599f',
            //         'fill-opacity': 0.55
            //     }
            // }, 'ENMK_717000-label');
        });

        map.on('draw.create', this.onDrawFinished);

        map.on('move', () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2)
            });
        });

        map.on('mousemove', ['ENMK_717000','ENMK_717000_0','ENMK_717000_1','ENMK_717000_2','ENMK_717000_3','ENMK_717000_4','ETKOV_122111'], (e) => {
            // if we have highlighted features, change their highlight state to false
            this.highlightFeatures([this.selectFeature(e.features)]);
            this.props.selectFeature(this.feature[0]);
            this.props.clearHighlight();
        });

        map.on('mouseleave', ['ENMK_717000','ENMK_717000_0','ENMK_717000_1','ENMK_717000_2','ENMK_717000_3','ENMK_717000_4','ETKOV_122111'], () => {
            this.highlightFeatures();
            this.feature = null;
            this.props.selectFeature(null);
            this.props.clearHighlight();
        });

        map.on('click', ['ENMK_717000','ENMK_717000_0','ENMK_717000_1','ENMK_717000_2','ENMK_717000_3','ENMK_717000_4','ETKOV_122111'], (e) => {
            if (!this.clickEnabled)
                return;
            const feature = this.selectFeature(e.features);
            this.props.selected(feature, e.lngLat);
            const fs = this.features.filter((i) => i.id !== feature.id);
            if (fs.length !== this.features.length)
                this.features = fs;
            else
                this.features.push(feature);

            if (this.features.length) {
                const sids = this.features.map((i) => ['==', ['id'], i.id]);
                map.setFilter('ENMK_717000-selected', ['any',  ...sids]);
            } else {
                map.setFilter('ENMK_717000-selected', ['any',  ['==', ['id'], '']]);
            }
        });
    }

    render() {
        return (
            <Box sx={{width: '100%', height: '100%'}} ref={this.mapContainerRef} className='map-container'/>
        );
    }
}

export default MapReact;
